import React from "react";
import CodeBlock from "./CodeBlock";

interface MessageBubbleProps {
  content: string;
  type: "text" | "code";
  isTyping?: boolean;
  isUser?: boolean;
}

const MessageBubble = ({
  content,
  type,
  isTyping,
  isUser,
}: MessageBubbleProps) => {
  return (
    <div
      className={`flex ${isTyping ? "animate-fade-in" : ""} ${
        isUser && type === "text" ? "justify-end" : "justify-start"
      }`}
    >
      <div
        className={`max-w-3xl ${
          isUser && type === "text"
            ? "bg-blue-500 text-white rounded-lg px-4 py-2"
            : ""
        }`}
      >
        {type === "code" ? (
          <CodeBlock code={content} />
        ) : (
          <p className="leading-relaxed">{content}</p>
        )}
      </div>
    </div>
  );
};

export default MessageBubble;
