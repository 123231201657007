import React, { useState, useEffect, useMemo } from "react";
import { MovieAi } from "../../../../interfaces/movie_ai";
import { InputField } from "../../../../interfaces/input_field";
import { MainCategory } from "../../../../interfaces/main_category";
import { adminsGetSubCategories } from "../../../../libs/api/admins/sub_category";
import { adminsGetLeafCategories } from "../../../../libs/api/admins/leaf_category";
import { UserGenMovie } from "@/interfaces/user_gen_movie";
import {
  adminCreateMovieAi,
  adminUpdateMovieAi,
} from "../../../../libs/api/admins/movie_ai";
import { adminBulkPostMovieAiForms } from "../../../../libs/api/admins/movie_ai_form";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import PromptSettingForm from "../../../users/ais/movie/PromptSettingForm";
import FormRow from "../../../admins/ais/form/FormRow";
import { createConsumer } from "@rails/actioncable";

import QueuedLoader from "@/components/users/ais/movie/QueuedLoader";

type Props = {
  movieAi: MovieAi;
  inputFields: InputField[];
  mainCategories: MainCategory[];
};

const AdminsMovieAisEdit: React.FC<Props> = (props) => {
  const [subCategories, setSubCategories] = useState([]);
  const [leafCategories, setLeafCategories] = useState([]);
  const [editImage, setEditImage] = useState(props.movieAi.image?.url == null);

  const [inputFields, setInputFields] = useState<InputField[]>(
    props.inputFields
  );
  const [streaming, setStreaming] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [logs, setLogs] = useState<string>("");
  const [progress, setProgress] = useState<string>("0");
  const [status, setStatus] = useState<string>("");
  const [movieUrl, setMovieUrl] = useState<string>("");

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.movieAi.name,
      intro: props.movieAi.intro,
      image: "",
      mainCategorySlug: props.movieAi.mainCategorySlug,
      subCategorySlug: props.movieAi.subCategorySlug,
      leafCategorySlug: props.movieAi.leafCategorySlug,
      prompt: props.movieAi.prompt,
      orderIdx: props.movieAi.orderIdx,
      status: props.movieAi.status,
      slug: props.movieAi.slug,
    },
  });

  useEffect(() => {
    if (props.movieAi.mainCategorySlug != null) {
      adminsGetSubCategories({
        mainCategorySlug: props.movieAi.mainCategorySlug,
      }).then(({ data }) => {
        setSubCategories(data);
      });
    }
  }, [props.movieAi.mainCategorySlug]);

  useEffect(() => {
    if (props.movieAi.subCategorySlug != null) {
      adminsGetLeafCategories({
        subCategorySlug: props.movieAi.subCategorySlug,
      }).then(({ data }) => {
        setLeafCategories(data);
      });
    }
  }, [props.movieAi.subCategorySlug]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("movie_ai[name]", data.name || "");
    formData.append("movie_ai[intro]", data.intro || "");
    if (data.image != undefined && data.image) {
      formData.append("movie_ai[image]", data.image);
    }

    formData.append("movie_ai[mainCategorySlug]", data.mainCategorySlug || "");
    formData.append("movie_ai[subCategorySlug]", data.subCategorySlug || "");
    formData.append("movie_ai[leafCategorySlug]", data.leafCategorySlug || "");
    formData.append("movie_ai[prompt]", data.prompt || "");
    formData.append("movie_ai[orderIdx]", data.orderIdx || "");
    formData.append("movie_ai[status]", data.status || "");

    if (data.slug != null) {
      formData.append("movie_ai[slug]", data.slug || "");
    }
    try {
      if (props.movieAi?.id != null) {
        await adminUpdateMovieAi(props.movieAi.slug, formData);
        toastSuccess("更新しました");
      } else {
        await adminCreateMovieAi(formData);
        toastSuccess("作成しました");
      }
    } catch (error) {
      let errorMessage = error.response.data.errors.join(", ");
      toast.error(`更新に失敗しました。${errorMessage}`);
      console.error(error);
    }
  };
  const toastSuccess = (msg: string) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleFileChange = (event) => {
    setValue("image", event.target.files[0]);
  };

  const onChangeMainCategory = async (v: string) => {
    setSubCategories([]);
    setLeafCategories([]);
    let { data } = await adminsGetSubCategories({
      mainCategorySlug: v,
      kind: "movie",
    });
    setSubCategories(data);
  };

  const onChangeSubCategory = async (v: string) => {
    setLeafCategories([]);
    let { data } = await adminsGetLeafCategories({
      subCategorySlug: v,
    });
    setLeafCategories(data);
  };

  const cable = useMemo(() => createConsumer(), []);

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };
  const onSubmitPromptSettingForm = async (data: any) => {
    const token = generateRandomString(20);
    data.movie_ai_slug = props.movieAi.slug;
    setStreaming(true);

    const channel = cable.subscriptions.create(
      { channel: "PrivateMovieChannel", token: token },
      {
        connected: () => {
          console.log("WebSocket connected");
          channel.perform("generate", { form_data: data, token: token });
        },
        disconnected: () => {
          console.log("WebSocket disconnected");
        },
        received: (res) => {
          console.log("res", res);
          if (res.error) {
            toast.error(res.error);
            setStreaming(false);
          }
          if (res.status) {
            setStatus(res.status);
            if (res.status == "succeeded") {
              setMovieUrl(res.data);
              setStreaming(false);
            } else if (res.status == "processing") {
              setLogs(res.logs);
              setProgress(res.progress);
            }
          }
        },
      }
    );
  };

  const removeAiForm = (imageAiForm: InputField, i: number) => {
    setInputFields((prevForms) => {
      return prevForms.filter((prevForm: InputField, j: number) => {
        if (i != j) {
          return prevForm;
        }
      });
    });
    setDeletedIds([...deletedIds, imageAiForm.id]);
  };

  const updateAiForm = (imageAiForm: InputField, i: number) => {
    setInputFields((prevForms) => {
      return prevForms.map((prevForm: InputField, j: number) => {
        if (i == j) {
          return imageAiForm;
        } else {
          return prevForm;
        }
      });
    });
  };

  const moveUp = (index) => {
    if (index === 0) return;
    const newData = [...inputFields];
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    setInputFields(newData);
  };

  const moveDown = (index) => {
    if (index === inputFields.length - 1) return;
    const newData = [...inputFields];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setInputFields(newData);
  };

  const newAiForm: InputField = {
    id: "",
    kind: `input_text`,
    inputName: `name${inputFields.length}`,
    label: "",
    options: [{ name: "", value: "" }],
    helpText: "",
    col: "6",
  };

  const addAiForms = () => {
    setInputFields([...inputFields, newAiForm]);
  };

  const submit = async () => {
    let { data } = await adminBulkPostMovieAiForms({
      movieAiId: props.movieAi.id,
      InputFields: inputFields,
      deletedIds: deletedIds,
    });
    setDeletedIds([]);
    setInputFields(data);
    toastSuccess("更新しました");
  };

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:rounded-md border">
        <form className="simple_form edit_ai" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6">
            <div>
              <div className="mb-4 string required ai_name">
                <label
                  className="block string required text-sm font-medium text-gray-600"
                  htmlFor="ai_name"
                >
                  名称
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 string required"
                  type="text"
                  id="ai_name"
                  {...register("name", { required: true })}
                />
                {errors.name?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            <div>
              {editImage ? (
                <>
                  <div className="mb-4 file required ai_image">
                    <label
                      className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                      htmlFor="ai_image"
                    >
                      アイコン画像
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                        *必須
                      </span>
                    </label>
                    <div className="grid grid-cols-12">
                      <input
                        className="col-span-10 w-full text-gray-500 border rounded mt-1 file required"
                        type="file"
                        name="ai[image]"
                        id="ai_image"
                        onChange={handleFileChange}
                      />
                      <button
                        type="button"
                        className="relative col-span-2 lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setEditImage(false)}
                      >
                        キャンセル
                      </button>
                    </div>
                    {errors.image?.type === "required" && (
                      <p className="font-semibold text-sm text-red-500">
                        入力してください
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label
                    className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                    htmlFor="ai_image"
                  >
                    アイコン画像
                  </label>
                  <div className="flex mt-1 items-center mb-4">
                    <img
                      src={props.movieAi.image.url}
                      className="w-20 mr-2 flex-shrink-0 rounded-full bg-gray-300"
                    />
                    <button
                      type="button"
                      className="relative lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setEditImage(true)}
                    >
                      変更
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
              <div className="mb-4 text optional ai_intro">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_intro"
                >
                  説明文
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={10}
                  id="ai_intro"
                  {...register("intro")}
                ></textarea>
              </div>
            </div>
            <div>
              <div className="mb-4 select required ai_main_category">
                <label
                  className="block select required text-sm font-medium text-gray-600"
                  htmlFor="ai_main_category_id"
                >
                  大カテゴリ
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select required"
                  id="ai_main_category_id"
                  {...register("mainCategorySlug", { required: true })}
                  onChange={(e) => onChangeMainCategory(e.target.value)}
                >
                  {props.mainCategories.map((c, i) => {
                    return (
                      <option key={`main-category-${i}`} value={c.slug}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
                {errors.mainCategorySlug?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            {subCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_sub_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_sub_category_id"
                  >
                    中カテゴリ
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_sub_category_id"
                    {...register("subCategorySlug")}
                    onChange={(e) => onChangeSubCategory(e.target.value)}
                  >
                    {subCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`sub-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            {leafCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_leaf_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_leaf_category_id"
                  ></label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_leaf_category_id"
                    {...register("leafCategorySlug")}
                  >
                    {leafCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`leaf-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            <div>
              <div className="mb-4 text optional ai_prompt">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_prompt"
                >
                  プロンプト
                </label>
                <div className="p-3 bg-gray-100 border mb-2 rounded-md">
                  <h2 className="text-sm">置換ルール</h2>
                  <div className="flex flex-wrap">
                    {props.inputFields.map((aiForm, i) => {
                      return (
                        <div
                          className="text-xs mt-1 text-gray-600 mr-2"
                          key={`ai-form-${i}`}
                        >
                          {aiForm.label}: %{aiForm.inputName}%
                        </div>
                      );
                    })}
                  </div>
                </div>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={20}
                  id="ai_prompt"
                  {...register("prompt")}
                ></textarea>
              </div>
            </div>

            <div>
              <div className="mb-4 integer optional ai_order_idx">
                <label
                  className="block integer optional text-sm font-medium text-gray-600"
                  htmlFor="ai_order_idx"
                >
                  並び順
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
                  type="number"
                  step="1"
                  id="ai_order_idx"
                  {...register(`orderIdx`)}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 enum optional ai_status">
                <label
                  className="block enum optional text-sm font-medium text-gray-600"
                  htmlFor="ai_status"
                >
                  公開ステータス
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   enum optional form-control"
                  id="ai_status"
                  {...register(`status`)}
                >
                  <option value="draft">下書き</option>
                  <option value="published">公開済み</option>
                </select>
              </div>
            </div>

            <div>
              <div className="mb-4 string optional ai_slug">
                <label
                  className="block string optional text-sm font-medium text-gray-600"
                  htmlFor="ai_slug"
                >
                  スラグ
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   string optional"
                  type="text"
                  id="ai_slug"
                  {...register(`slug`)}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <input
              type="submit"
              name="commit"
              value="送信する"
              className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            />
          </div>
        </form>
      </div>
      {props.movieAi.id != null && (
        <>
          <div className="shadow sm:overflow-hidden sm:rounded-md border mt-10">
            <div className="space-y-10 bg-white px-4 py-5 sm:p-6">
              {inputFields.map((aiForm, i) => {
                return (
                  <div
                    key={`ai-form-${i}-${aiForm.kind}-${aiForm.id}`}
                    className=""
                  >
                    <FormRow
                      aiForm={aiForm}
                      updateAiForm={(aiForm) => updateAiForm(aiForm, i)}
                      removeAiForm={() => removeAiForm(aiForm, i)}
                      index={i}
                      length={inputFields.length}
                      moveUp={moveUp}
                      moveDown={moveDown}
                    />
                  </div>
                );
              })}
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="rounded-md bg-white py-2 px-3 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={addAiForms}
                >
                  + 追加
                </button>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={submit}
              >
                保存する
              </button>
            </div>
          </div>

          <div className="py-3 mt-5">
            <h2 className="text-xl font-bold">Preview</h2>
          </div>
          <div className="shadow sm:overflow-hidden sm:roudned-md border">
            <PromptSettingForm
              submit={(datas) => onSubmitPromptSettingForm(datas)}
              inputFields={inputFields}
              streaming={streaming}
              cancelStreaming={cancelStreaming}
              remainGenerateCap={100}
              setSwitchModalOpen={() => {
                return false;
              }}
            />
          </div>
          <QueuedLoader
            logs={logs}
            status={status}
            movieUrl={movieUrl}
            progress={progress}
          />
        </>
      )}
    </>
  );
};
export default AdminsMovieAisEdit;
