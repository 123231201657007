import React, { useState, useEffect } from "react";
import { MovieAi } from "@/interfaces/movie_ai";
import { Meta } from "@/interfaces/meta";
import { MainCategory } from "@/interfaces/main_category";
import { adminSearchMovieAi } from "@/libs/api/admins/movie_ai";
import { toast } from "react-toastify";
import Pagination from "@/components/partials/navs/Pagination";
import DebounceText from "@/components/partials/forms/input/DebounceText";
import MainCategoryNavigation from "@/components/partials/navs/MainCategoryNavigation";
import Actions from "@/components/ais/list/Actions";

type Props = {
  mainCategories: MainCategory[];
  selectMainCategoryId?: string;
  selectCategoryPath: string;
};

const AdminsMovieAisIndex: React.FC<Props> = (props) => {
  const [searchMovieAis, setSearchMovieAis] = useState<MovieAi[]>([]);
  const [searchMeta, setSearchMeta] = useState<Meta | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await adminSearchMovieAi(
          "",
          props.selectMainCategoryId,
          undefined,
          false
        );
        console.log("response.data.movie_ais", response.data.movie_ais);
        setSearchMovieAis(response.data.movie_ais);
        setSearchMeta(response.data.meta);
      } catch (error) {
        toast.error("データの取得に失敗しました");
      }
    };

    fetchInitialData();
  }, [props.selectMainCategoryId]);

  const movePage = async (page: number) => {
    try {
      const response = await adminSearchMovieAi(
        searchText,
        props.selectMainCategoryId,
        page,
        false
      );
      setSearchMovieAis(response.data.movie_ais);
      setSearchMeta(response.data.meta);
      updateURLParams("page", response.data.meta.currentPage);
    } catch (error) {
      toast.error("検索に失敗しました");
    }
  };

  const updateURLParams = (key: string, value: string) => {
    const url = new URL(window.location.href);

    url.searchParams.set(key, value);

    window.history.replaceState({}, "", url.toString());
  };

  const textChanged = async (text: string) => {
    setSearchText(text);
    try {
      const response = await adminSearchMovieAi(
        text,
        props.selectMainCategoryId,
        undefined,
        false
      );
      setSearchMovieAis(response.data.movie_ais);
      setSearchMeta(response.data.meta);
      updateURLParams("page", response.data.meta.currentPage);
    } catch (error) {
      toast.error("検索に失敗しました");
    }
  };

  return (
    <>
      <h2 className="text-md font-semibold text-gray-900 mt-3">
        カテゴリを絞り込む
      </h2>
      <MainCategoryNavigation
        mainCategories={props.mainCategories}
        selectMainCategoryId={props.selectMainCategoryId}
        selectCategoryPath={props.selectCategoryPath}
      />
      <div className="mt-5 relative inline-block text-left w-full">
        <label
          htmlFor="search"
          className="block text-md font-semibold text-gray-900"
        >
          生成ツールをキーワードで検索
        </label>
        <div className="relative mt-2 flex items-center">
          <DebounceText
            onChange={textChanged}
            className="border border-gray-300 rounded-md p-2 w-full"
            placeholder="例)ブログ"
            name="search"
          />
        </div>
      </div>
      <div className="relative overflow-x-auto mt-5">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-3 py-3" scope="col">
                画像
              </th>
              <th className="px-3 py-3" scope="col">
                名前
              </th>
              <th className="px-3 py-3" scope="col">
                大カテゴリー
              </th>
              <th className="px-3 py-3" scope="col">
                中カテゴリー
              </th>
              <th className="px-3 py-3" scope="col">
                小カテゴリー
              </th>
              <th className="px-3 py-3" scope="col">
                並び順
              </th>
              <th className="px-3 py-3" scope="col">
                生成回数
              </th>
              <th className="px-3 py-3" scope="col">
                公開日
              </th>
              <th className="px-3 py-3" scope="col">
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            {searchMovieAis.map((movieAi) => {
              return (
                <tr
                  key={`movieAi-${movieAi.id}-${movieAi.slug}`}
                  className="bg-white border-b"
                >
                  <th className="px-3 py-4 w-24">
                    <img
                      className="w-full max-w-xs flex-shrink-0 rounded-full bg-gray-300"
                      src={movieAi.image?.thumb?.url}
                    />
                  </th>
                  <th className="px-3 py-4">{movieAi.name}</th>
                  <td className="px-3 py-4">{movieAi.mainCategoryName}</td>
                  <td className="px-3 py-4">{movieAi.subCategoryName}</td>
                  <td className="px-3 py-4">{movieAi.leafCategoryName}</td>
                  <td className="px-3 py-4">{movieAi.orderIdx}</td>
                  <td className="px-3 py-4">{movieAi.generateCount}</td>
                  <td className="px-3 py-4">{movieAi.publishedAt}</td>
                  <td className="px-3 py-4">
                    <Actions
                      editPath={movieAi.editAdminMovieAiPath}
                      deletePath={movieAi.adminMovieAiPath}
                      slug={movieAi.slug}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {searchMeta && <Pagination meta={searchMeta} selectPage={movePage} />}
    </>
  );
};

export default AdminsMovieAisIndex;
