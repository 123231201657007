import getClient from "../client";
import { PostMovieAiFormParams } from "../../../interfaces/requests/movie_ai_form";

/**
 * サブカテゴリー一覧を取得
 */
export const adminBulkPostMovieAiForms = async (
  params: PostMovieAiFormParams
) => {
  return getClient().post(`/admins/movie_ai_forms/bulk_create.json`, params);
};
