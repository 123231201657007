import getClient from "../client";
import {
  GetAisParams,
  PaginateAisParams,
} from "../../../interfaces/requests/ai";
/**
 * サブカテゴリー一覧を取得
 */
export const usersGetMovieAis = async (params: GetAisParams) => {
  return getClient().get(`/users/movie_ais.json`, {
    params: params,
  });
};

export const usersPaginateMovieAis = async (params: PaginateAisParams) => {
  const response = await getClient().get(`/movie_ais/paginate.json`, {
    params: params,
  });
  return response.data; // AxiosResponseのdata部分を返す
};

/*
  サジェスチョン
 */
export const suggestMovieAi = async (query: string) => {
  const response = await getClient().get(`/users/movie_ais/suggestion.json`, {
    params: {
      q: query,
    },
  });
  return response.data; // AxiosResponseのdata部分を返す
};
