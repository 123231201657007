import React, { useState } from "react";
import { User } from "../../../interfaces/user";
import { Plan } from "../../../interfaces/plan";
import { UserPlan } from "../../../interfaces/user_plan";
import { Coupon } from "../../../interfaces/coupon";
import ChangeOmniPlanConfirm from "../../partials/modals/ChangeOmniPlanConfirm";
import ChangeToFreeConfirm from "../../partials/modals/ChangeToFreeConfirm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ArrowRight } from "lucide-react";

type Props = {
  plans: Plan[];
  user: User;
  userPlan: UserPlan;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  coupon: Coupon | null;
  serviceType: "text" | "image" | "whisper" | "movie" | "voice";
  filterType: "span" | "generateLimit";
};
const UsersPlansTable: React.FC<Props> = (props) => {
  const [planToChange, setPlanToChange] = useState<Plan | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openFreeModal, setOpenFreeModal] = useState(false);
  const stripePromise = loadStripe(props.stripeKey);
  const isFree = () => {
    //@todo
    return props.userPlan == null;
  };

  console.log("props.serviceType", props.serviceType);

  const isCurrentPlan = (plan: Plan) => {
    return props.userPlan?.plan?.id == plan.id;
  };

  const planBtn = (plan: Plan) => {
    if (isCurrentPlan(plan)) {
      return currentPlanBtn();
    } else {
      return upgradeBtn(plan);
    }
  };

  const upgradeBtn = (plan: Plan) => {
    // props.user.hasCard で条件分岐
    return (
      <a
        href={`#`}
        className={`text-white bg-gradient-to-r ${getUpgradeBtnBgColor()} hover:opacity-90 focus:ring-4 focus:ring-blue-200 font-bold rounded-full text-lg py-3 text-center my-8 shadow-md`}
        onClick={(e) => {
          e.preventDefault();
          handleClickPlanChange(plan);
        }}
      >
        <div className="relative flex justify-center items-center">
          {props.user.planDallE != null ? (
            <div>このオプションに変更</div>
          ) : (
            <div>このオプションを追加</div>
          )}
          <ArrowRight className="absolute right-3 w-6 h-6" />
        </div>
      </a>
    );
  };

  const downgradeFreeBtn = () => {
    return (
      <a
        href={`#`}
        className="text-gray-800 bg-gray-300 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-8"
        onClick={(e) => {
          e.preventDefault();
          handleClickFree();
        }}
      >
        ダウングレードする
      </a>
    );
  };

  const currentPlanBtn = () => {
    return (
      <a
        href="#"
        className="text-gray-800 bg-gray-300 focus:ring-4 font-bold rounded-lg text-md px-5 py-4 text-center my-8"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        現在のプラン
      </a>
    );
  };

  const handleClickPlanChange = (plan: Plan) => {
    setPlanToChange(plan);
    setOpenModal(true);
  };
  const handleClickFree = () => {
    setOpenFreeModal(true);
  };

  const trialLabel = (trialPeriodDays) => {
    if (trialPeriodDays == null) {
      return <></>;
    }
    if (props.user.hasExperiencedTrial) {
      return <></>;
    }
    if (props.user.hasCard) {
      return <></>;
    }
    return (
      <div className="py-1 px-3 text-sm text-blue-800 bg-blue-100 rounded font-bold">
        <>クラウドワークスユーザー様限定</>
        <br />
        <>{trialPeriodDays}日間無料トライアル</>
      </div>
    );
  };

  const toYen = (amount) => {
    return amount.toLocaleString();
  };

  const toYenPerLimit = (amountPerMonth, limit) => {
    return Math.round(amountPerMonth / limit);
  };

  const orderClassName = (plan: Plan) => {
    if (plan.interval == "year") {
      return "order-1";
    } else {
      if (plan.intervalCount == 6) {
        return "order-2";
      } else {
        return "order-3";
      }
    }
  };

  const planTermName = (plan: Plan) => {
    if (props.filterType == "generateLimit" && plan.serviceType == "whipser") {
      if (plan.interval == "year") {
        return "年間プラン";
      } else {
        if (plan.intervalCount == 6) {
          return "半年プラン";
        } else {
          return "毎月プラン";
        }
      }
    } else {
      return plan.name;
    }
  };

  const planWhisperLabelName = (plan: Plan) => {
    if (props.filterType == "generateLimit") {
      if (plan.interval == "year") {
        return "一番お得";
      } else {
        if (plan.intervalCount == 6) {
          return "少しお得";
        } else {
          return "通常価格";
        }
      }
    } else {
      if (plan.serviceType == "whisper") {
        if (plan.generateLimit == 1200) {
          return "たくさん使うなら！";
        } else if (plan.generateLimit == 600) {
          return "本格的に使う！";
        } else if (plan.generateLimit == 350) {
          return "一番お得！";
        } else if (plan.generateLimit == 100) {
          return "少しお得";
        } else {
          return false;
        }
      } else if (plan.serviceType == "movie") {
        if (plan.generateLimit == 1000) {
          return "たくさん使うなら！";
        } else if (plan.generateLimit == 600) {
          return "本格的に使う！";
        } else if (plan.generateLimit == 300) {
          return "少しお得";
        } else {
          return false;
        }
      }
    }
  };

  const planWhisperDesc = (plan: Plan) => {
    return (
      "動画・音声1分あたり約" +
      toYenPerLimit(plan.amountPerMonth, plan.generateLimit) +
      "円で生成"
    );
  };

  const planMovieDesc = (plan: Plan) => {
    return (
      "動画生成1秒あたり約" +
      toYenPerLimit(plan.amountPerMonth, plan.generateLimit) +
      "円で生成"
    );
  };

  const totalAmount = (plan: Plan) => {
    if (plan.interval == "month" && plan.intervalCount == 1) {
      return (
        <>
          <div className="text-xs mt-1 font-normal text-gray-900">
            ※契約は自動更新となります
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="text-xs mt-2 font-normal text-gray-900">
            ¥ {toYen(plan.amount)}(税込){perIntervalText(plan)}
          </div>
          <div className="text-xs mt-1 font-normal text-gray-900">
            ※契約は自動更新となります
          </div>
        </>
      );
    }
  };

  const perIntervalText = (plan: Plan) => {
    if (plan.interval == "year") {
      return "の年間ごとのお支払いになります";
    } else if (plan.interval == "month" && plan.intervalCount == 6) {
      return "の6ヶ月ごとのお支払いになります";
    }
  };
  const couponTag = (plan: Plan) => {
    if (plan.interval == "year") {
      if (props.coupon && props.coupon?.discountAmount != 0) {
        return (
          <span
            className="py-1 px-3 text-md bg-blue-100 rounded font-bold text-white"
            style={linearGradientStyle}
          >
            {props.coupon?.discountAmount}円クーポン適応中
          </span>
        );
      }
    }
  };

  const linearGradientStyle = {
    background: "linear-gradient(90deg, #6a11cb 0%, #2575fc 100%)",
  };

  const getUpgradeBtnBgColor = () => {
    if (props.serviceType === "whisper") {
      return "from-green-600 to-green-400";
    } else if (props.serviceType === "video") {
      return "from-rose-600 to-rose-400";
    } else if (props.serviceType === "voice") {
      return "from-cyan-600 to-cyan-400";
    } else {
      return "from-blue-600 to-blue-400";
    }
  };

  const getPlanLabelColor = () => {
    if (props.serviceType === "whisper") {
      return "text-green-500";
    } else if (props.serviceType === "video") {
      return "text-rose-500";
    } else if (props.serviceType === "voice") {
      return "text-cyan-500";
    } else {
      return "text-blue-500";
    }
  };

  const getPlanLabelBgColor = () => {
    if (props.serviceType === "whisper") {
      return "bg-green-500";
    } else if (props.serviceType === "video") {
      return "bg-rose-500";
    } else if (props.serviceType === "voice") {
      return "bg-cyan-500";
    } else {
      return "bg-blue-500";
    }
  };

  const getPlanDescColor = () => {
    if (props.serviceType === "whisper") {
      return "text-green-500";
    } else if (props.serviceType === "video") {
      return "text-rose-500";
    } else if (props.serviceType === "voice") {
      return "text-cyan-500";
    } else {
      return "text-blue-500";
    }
  };

  const getPlanDescBgColor = () => {
    if (props.serviceType === "whisper") {
      return "bg-green-100";
    } else if (props.serviceType === "video") {
      return "bg-rose-600/10";
    } else if (props.serviceType === "voice") {
      return "bg-cyan-600/10";
    } else {
      return "bg-blue-600/10";
    }
  };

  const planDesc = (plan: Plan) => {
    if (plan.interval == "year") {
      return "最大40%お得なプラン";
    } else {
      if (plan.intervalCount == 6) {
        return "最大20%お得なプラン";
      } else {
        return "通常価格";
      }
    }
  };

  return (
    <>
      <div className="grid gap-8 lg:grid-cols-3 xl:gap-10">
        {isFree() ? (
          <>{/* プレミアムプラン参加してない場合フリー非表示 */}</>
        ) : (
          // プラン加入してる人にフリープラン表示
          <>
            <div
              className={`order-5 flex flex-col p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
            >
              <h3 className="mb-4 text-2xl font-medium text-gray-900">
                無料枠（月ごとのリセットなし）
              </h3>
              <span className="text-5xl font-extrabold text-gray-900">¥ 0</span>
              {isFree() ? <>{currentPlanBtn()}</> : <>{downgradeFreeBtn()}</>}

              <ul role="list" className="space-y-4 text-left text-gray-900">
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-ad w-5"></i>
                  <span>文字起こし累計10分まで</span>
                </li>
              </ul>
            </div>
          </>
        )}

        {props.plans.map((plan) => {
          return (
            <div
              key={plan.id}
              className={`${orderClassName(
                plan
              )} flex relative flex-col p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
            >
              {couponTag(plan)}
              <div className="mb-2">{trialLabel(plan.trialPeriodDays)}</div>
              <div className="flex items-center justify-between gap-x-4 mb-4">
                <h3
                  id="tier-startup"
                  className={`text-lg font-semibold leading-8 ${getPlanLabelColor()}`}
                >
                  {planTermName(plan)}
                </h3>
                {plan.generateLimit != 100 && planWhisperLabelName(plan) && (
                  <p
                    className={`rounded-sm ${getPlanLabelBgColor()} px-2.5 py-1 text-xs font-semibold leading-5 text-white`}
                  >
                    {planWhisperLabelName(plan)}
                  </p>
                )}
              </div>
              <div className="flex items-end justify-center">
                <div className="text-3xl font-extrabold text-gray-900">¥</div>
                <div className="text-5xl font-extrabold text-gray-900">
                  {toYen(plan.amountPerMonth)}
                </div>
                <div className="text-lg font-extrabold text-gray-900">
                  &nbsp;/&nbsp;月(税込)
                </div>
              </div>
              {totalAmount(plan)}
              {props.filterType == "generateLimit" && (
                <span
                  className={`rounded-sm mt-4 ${getPlanDescBgColor()} px-2.5 py-1 text-xs font-semibold leading-5 ${getPlanDescColor()}`}
                >
                  {planDesc(plan)}
                </span>
              )}

              <div
                className={`rounded-sm mt-6 text-sm space-y-2 font-semibold ${getPlanDescColor()}`}
              >
                <div className="flex items-center">
                  <i className="fa-solid fa-circle-check mr-2"></i>
                  {props.serviceType == "whisper" && (
                    <span>{planWhisperDesc(plan)}</span>
                  )}
                  {props.serviceType == "movie" && (
                    <span>{planMovieDesc(plan)}</span>
                  )}
                </div>
                <div className="flex items-center">
                  <i className="fa-solid fa-circle-check mr-2"></i>
                  {props.serviceType == "whisper" && (
                    <span>
                      毎月{toYen(plan.generateLimit)}分まで文字起こし可能！
                    </span>
                  )}
                  {props.serviceType == "movie" && (
                    <span>
                      毎月{toYen(plan.generateLimit)}秒までの動画生成可能！
                    </span>
                  )}
                </div>
              </div>

              {planBtn(plan)}
            </div>
          );
        })}
      </div>
      <Elements stripe={stripePromise}>
        {planToChange != null && (
          <ChangeOmniPlanConfirm
            open={openModal}
            userPlan={props.userPlan}
            setOpen={setOpenModal}
            title={
              props.userPlan != null
                ? "以下のオプションに変更します。よろしいでしょうか？"
                : "以下のオプションを追加します。よろしいでしょうか？"
            }
            cta={props.userPlan != null ? "変更する" : "追加する"}
            plan={planToChange}
            stripeKey={props.stripeKey}
            ThreeDSecureSupported={props.ThreeDSecureSupported}
            user={props.user}
          />
        )}
      </Elements>
      <ChangeToFreeConfirm
        open={openFreeModal}
        setOpen={setOpenFreeModal}
        title={`フリープランに変更します。`}
        message={`本当にダウングレードしますか？`}
        user={props.user}
        omni={true}
        serviceType={props.serviceType}
      />
    </>
  );
};
export default UsersPlansTable;
