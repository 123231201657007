import React, { useEffect, useState } from "react";
import { User } from "../../../../interfaces/user";
import { UserPlan } from "../../../../interfaces/user_plan";
import { Plan } from "../../../../interfaces/plan";
import { Coupon } from "../../../../interfaces/coupon";
type Props = {
  plans: Plan[];
  user: User;
  userPlan: UserPlan;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  coupon: Coupon | null;
  serviceType: "text" | "image" | "whisper" | "movie" | "voice";
  filterType: "span" | "generateLimit";
};
import PlanTable from "../../../users/omni_plans/Table";
import PlanFaqs from "../../../users/plans/Faqs";
import PlanMenu from "../../../users/omni_plans/MenuSpan";
import PlanMenuLimit from "../../../users/omni_plans/MenuLimit";

const UsersPlans: React.FC<Props> = (props) => {
  const [plans, setPlans] = useState(props.plans);
  const [interval, setInterval] = useState(
    props.userPlan?.plan?.interval || "year"
  );
  const [intervalCount, setIntervalCount] = useState(
    props.userPlan?.plan?.intervalCount || 1
  );
  const [generateLimit, setGenerateLimit] = useState(
    props.userPlan?.plan?.generateLimit ||
      (props.serviceType === "whisper" ? 600 : 0)
  );

  const filterPlans = (span) => {
    let newInterval = "year";
    let newIntervalCount = 1;

    switch (span) {
      case "annual":
        newInterval = "year";
        newIntervalCount = 1;
        break;
      case "halfYear":
        newInterval = "month";
        newIntervalCount = 6;
        break;
      case "month":
        newInterval = "month";
        newIntervalCount = 1;
        break;
      default:
        break;
    }

    const filteredPlans = props.plans.filter(
      (plan) =>
        plan.interval === newInterval && plan.intervalCount === newIntervalCount
    );
    setInterval(newInterval);
    setIntervalCount(newIntervalCount);
    setPlans(filteredPlans);
  };

  const filterPlansByLimit = (limit) => {
    const filteredPlans = props.plans.filter(
      (plan) => plan.generateLimit === limit
    );
    setGenerateLimit(limit);
    setPlans(filteredPlans);
  };

  useEffect(() => {
    if (props.filterType == "span" || props.serviceType == "movie") {
      if (props.userPlan == null) {
        const defaultInterval = props.plans.some(
          (plan) => plan.interval === "year"
        )
          ? "year"
          : "month";
        filterPlans(defaultInterval);
      } else {
        filterPlans(props.userPlan.plan?.interval);
      }
    } else if (props.filterType == "generateLimit") {
      if (props.userPlan == null) {
        const defaultGenerateLimit =
          props.plans.find((plan) => plan.generateLimit)?.generateLimit || 0;
        filterPlansByLimit(defaultGenerateLimit);
      } else {
        filterPlansByLimit(props.userPlan.plan?.generateLimit);
      }
    }
  }, [props.userPlan]);

  const intervalText = () => {
    if (interval == "year") {
      return "一年分一括払い";
    } else if (interval == "month" && intervalCount == 6) {
      return "6ヶ月分一括払い";
    }
  };

  const generateLimits = (): number[] => {
    const uniqueLimits = new Set<number>();
    props.plans.forEach((plan) => {
      uniqueLimits.add(plan.generateLimit);
    });
    return Array.from(uniqueLimits);
  };

  const availablePlanTypes = () => {
    const types = [];
    if (
      props.plans.some(
        (plan) => plan.interval === "month" && plan.intervalCount === 1
      )
    ) {
      types.push("month");
    }
    if (
      props.plans.some(
        (plan) => plan.interval === "month" && plan.intervalCount === 6
      )
    ) {
      types.push("halfYear");
    }
    if (props.plans.some((plan) => plan.interval === "year")) {
      types.push("annual");
    }
    return types;
  };

  return (
    <>
      {!(interval == "month" && intervalCount == 1) &&
        props.filterType == "span" && (
          <p className="mb-6 text-center">
            {intervalText()}
            になるので月払いプランより一回辺りのお値段がかなりお安い価格になっています。
          </p>
        )}
      <div className="flex justify-center mb-12">
        {props.filterType == "span" || props.serviceType == "movie" ? (
          <PlanMenu
            user={props.user}
            userPlan={props.userPlan}
            filterPlans={filterPlans}
            serviceType={props.serviceType}
            availablePlanTypes={availablePlanTypes()}
          />
        ) : (
          <PlanMenuLimit
            user={props.user}
            userPlan={props.userPlan}
            filterPlansByLimit={filterPlansByLimit}
            serviceType={props.serviceType}
            generateLimit={generateLimit}
            setGenerateLimit={setGenerateLimit}
            generateLimits={generateLimits()}
          />
        )}
      </div>
      <PlanTable
        plans={plans}
        userPlan={props.userPlan}
        user={props.user}
        stripeKey={props.stripeKey}
        ThreeDSecureSupported={props.ThreeDSecureSupported}
        coupon={props.coupon}
        serviceType={props.serviceType}
        filterType={props.filterType}
      />
      <PlanFaqs />
    </>
  );
};
export default UsersPlans;
