import React, { useContext } from "react";
import Lists from "./sidebar/Lists";
import ListsForeign from "./sidebar/ListsForeign";
import NotLoginLists from "./sidebar/NotLoginLists";
import { Ai } from "../../interfaces/ai";
import { User } from "../../interfaces/user";
import { UserSavedText } from "../../interfaces/user_saved_text";
import Cookies from "js-cookie";
const googtransValue = Cookies.get("googtrans");

type Props = {
  user?: User;
  favorites: Ai[];
  savedTexts: UserSavedText[];
};
const Sidebar: React.FC<Props> = (props) => {
  const dotCss = () => {
    if (props.user && props.user.isPaidText) {
      return "fill-green-400";
    } else {
      return "fill-gray-400";
    }
  };

  return (
    <div className="h-screen px-3 pb-24 overflow-y-auto bg-white">
      {props.user ? (
        <div className="p-2 mt-2">
          <div className="flex items-center">
            <i className="fas fa-user-circle mr-3 w-8 h-8 text-gray-400"></i>
            <div className="text-left">
              <div className="font-semibold leading-none text-gray-900 mb-0.5">
                {props.user.name}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-2 mt-2">
          <div className="flex items-center">
            <i className="fas fa-user-circle mr-3 w-8 h-8 text-gray-400"></i>
            <div className="text-left">
              <div className="font-semibold leading-none text-gray-900 mb-0.5">
                ゲスト 様
              </div>
            </div>
          </div>
        </div>
      )}

      {/* プランバッジ */}
      {props.user && (
        <div className={`mb-4`}>
          <div
            className={`inline-flex items-center justify-center w-full gap-x-1.5 rounded-full bg-white border text-gray-900 py-2 text-xs font-medium mt-1`}
          >
            <svg
              className={`h-2 w-2 ${dotCss()}`}
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {props.user.planName} プラン
            {props.user.isPaidDallE && (
              <div className="text-xs text-center ml-2 text-orange-500">
                <i className="fas fa-image" />+
              </div>
            )}
            {props.user.isPaidWhisper && (
              <div className="text-xs text-center ml-2 text-green-500">
                <i className="fas fa-file-audio" />+
              </div>
            )}
            {props.user.isPaidMovie && (
              <div className="text-xs text-center ml-2 text-blue-500">
                <i className="fas fa-file-video" />+
              </div>
            )}
          </div>
          {/*
          {props.user.isPaidDallE && (
            <div className="text-xs text-center text-orange-500">
              <i className="fas fa-image" /> 画像生成 +
            </div>
          )}
          */}
        </div>
      )}

      {props.user ? (
        <>
          {googtransValue && googtransValue != "/ja/ja" ? (
            <ListsForeign
              favorites={props.favorites}
              savedTexts={props.savedTexts}
              user={props.user}
            />
          ) : (
            <Lists
              favorites={props.favorites}
              savedTexts={props.savedTexts}
              user={props.user}
            />
          )}
        </>
      ) : (
        <>
          <NotLoginLists />
        </>
      )}
    </div>
  );
};
export default Sidebar;
