import getClient from "../client";

export const adminCloneMovieAi = async (slug: string) => {
  return getClient().post(`/admins/movie_ais/${slug}/clone.json`);
};

export const adminCreateMovieAi = async (params) => {
  return getClient().post(`/admins/movie_ais.json`, params);
};

export const adminUpdateMovieAi = async (slug: string, params) => {
  return getClient().put(`/admins/movie_ais/${slug}.json`, params);
};

export const adminSearchMovieAi = async (
  searchText: string,
  mainCategoryId?: string,
  page?: number,
  forWhisper?: boolean
) => {
  const params: Record<string, any> = {
    q: searchText,
    mainCategoryId: mainCategoryId,
    page: page,
  };
  return getClient().get(`/admins/movie_ais/search.json`, {
    params: params,
  });
};
