import React, { useState } from "react";
import { Copy, Check } from "lucide-react";
import { toast } from "react-toastify";

interface CodeBlockProps {
  code: string;
}

const CodeBlock = ({ code }: CodeBlockProps) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      toast.success("コードをコピーしました");
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast.error("コピーに失敗しました");
    }
  };

  return (
    <div className="relative group w-full">
      <button
        onClick={copyToClipboard}
        className="absolute top-2 right-2 p-2 rounded-md bg-gray-800 text-gray-300 opacity-0 group-hover:opacity-100 transition-opacity"
        aria-label="Copy code"
      >
        {copied ? <Check size={16} /> : <Copy size={16} />}
      </button>
      <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg w-full">
        <code className="block whitespace-pre-wrap break-all font-mono">
          {code}
        </code>
      </pre>
    </div>
  );
};

export default CodeBlock;
