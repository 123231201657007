import React, { useState, FormEvent } from "react";
import { Send } from "lucide-react";

interface ChatInputProps {
  onSubmit: (message: string) => void;
  disabled?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSubmit, disabled }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!message.trim() || disabled) return;

    onSubmit(message);
    setMessage("");
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="fixed bottom-0 bg-white z-10 w-2/3 left-1/2 transform -translate-x-[calc(50%-8rem)]"
    >
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={disabled}
        placeholder="メッセージを入力してください..."
        className="w-full p-4 pr-12 rounded-lg border border-gray-200 focus:border-gray-300 focus:ring-0 resize-none bg-white text-gray-900"
        rows={3}
        style={{ minHeight: "80px" }}
      />
      <button
        type="submit"
        disabled={disabled || !message.trim()}
        className="absolute right-4 bottom-4 p-2 text-gray-500 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
      >
        <Send size={20} />
      </button>
    </form>
  );
};

export default ChatInput;
