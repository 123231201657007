import React, { useState } from "react";
import { InputField } from "../../../../interfaces/input_field";
import InputText from "../forms/InputText";
import TextArea from "../forms/TextArea";
import InputSelect from "../forms/InputSelect";
import { useForm } from "react-hook-form";
import { User } from "../../../../interfaces/user";
import { UserGenMovie } from "../../../../interfaces/user_gen_movie";

type Props = {
  submit: (data: any) => void;
  inputFields: InputField[];
  streaming: boolean;
  cancelStreaming: () => void;
  user?: User;
  remainGenerateCap: number;
  setSwitchModalOpen?: (v: boolean) => void;
  userGenMovie?: UserGenMovie;
};
const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const num_options: number[] = [];

  const [publish, setPublish] = useState(true);

  const size_options = [
    {
      name: "横長:(854x480 px)",
      val: "854/480",
    },
    {
      name: "縦長(480x854 px)",
      val: "480/854",
    },
    {
      name: "正方形(480x480 px)",
      val: "480/480",
    },
  ];

  const {
    register,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const inputTag = (aiForm, idx) => {
    if (aiForm.kind == "input_text") {
      return (
        <InputText
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "text_area") {
      return (
        <TextArea
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "input_select") {
      return (
        <InputSelect
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "input_radio") {
    }
  };

  const onSubmit = async () => {
    // 手動でバリデーションを行う
    const result = await trigger();
    if (result) {
      const data = getValues();
      data.duration = "5"; // 一旦5で固定
      props.submit(data);
    }
  };

  const submitBtn = () => {
    if (props.streaming == true) {
      return (
        <button className="w-full rounded-full bg-gradient-to-r from-red-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90">
          生成中です...
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={onSubmit}
          className="w-full rounded-full bg-gradient-to-r from-blue-500 to-purple-600 py-3 mb-4 text-md font-semibold text-white shadow-sm hover:opacity-90"
        >
          生成する
        </button>
      );
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <div className="sm:shadow-lg sm:rounded-lg">
          <div className="space-y-6 bg-white py-6 px-2 sm:p-6">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-6 gap-3">
              {props.inputFields.map((aiForm, idx) => {
                return inputTag(aiForm, idx);
              })}
              <div className={`col-span-4`}>
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  サイズ
                </label>
                <div className="mt-1">
                  <select
                    id="col"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
                    {...register(`size`, {
                      required: true,
                    })}
                  >
                    {size_options.map((option, i) => {
                      return (
                        <option key={`kind-option-${i}`} value={option.val}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors[`size`]?.type === "required" && (
                  <p className="font-semibold text-sm text-red-400">
                    選択してください
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="bg-[#f7f9f9] px-4 py-3 text-right sm:px-6">
            {submitBtn()}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
