import React, { useState, useRef, useEffect } from "react";
import ChatInput from "@/components/ais/asistant_chat/ChatInput";
import MessageBubble from "@/components/ais/asistant_chat/MessageBubble";
import { Ai } from "@/interfaces/ai";
import Header from "../../../users/ais/chat/Header";

interface Message {
  content: string;
  type: "text" | "code";
}

const mockResponse = [
  {
    content:
      "Google Apps Script（GAS）を使用して、フォームからの問い合わせを自動処理するシステムについて説明します。",
    type: "text",
  },
  {
    content: `// フォーム送信時に実行される関数
function onFormSubmit(e) {
  var formResponse = e.namedValues;
  var email = formResponse['メールアドレス'][0];
  var name = formResponse['名前'][0];
  var content = formResponse['問い合わせ内容'][0];

  // カレンダーにイベントを追加
  var calendar = CalendarApp.getDefaultCalendar();
  calendar.createEvent('問い合わせ: ' + name, new Date(), new Date(), {description: content});

  // メールを自動返信
  GmailApp.sendEmail(email, 'お問い合わせありがとうございます', 'お問い合わせ内容を受け付けました。\\n\\n' + content);
}`,
    type: "code",
  },
  {
    content:
      "このスクリプトを実行するためには、トリガーの設定が必要です。以下のコードでトリガーを設定できます：",
    type: "text",
  },
  {
    content: `// トリガーの設定
function createTrigger() {
  var form = FormApp.getActiveForm();
  ScriptApp.newTrigger('onFormSubmit')
    .forForm(form)
    .onFormSubmit()
    .create();
}`,
    type: "code",
  },
  {
    content:
      "上記のスクリプトを保存して、createTrigger関数を実行することで、フォーム送信時に自動でスクリプトが実行されるようになります。",
    type: "text",
  },
] as Message[];

const Index: React.FC<{ ai: Ai }> = ({ ai }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentResponseParts, setCurrentResponseParts] = useState<string[]>(
    []
  );
  const [isTyping, setIsTyping] = useState(false);
  const [currentMessageType, setCurrentMessageType] = useState<
    "text" | "code" | null
  >(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentResponseParts]);

  const simulateTyping = async (response: Message[]) => {
    setIsTyping(true);
    for (const message of response) {
      setCurrentResponseParts([]);
      setCurrentMessageType(message.type);
      const chars = message.content.split("");
      for (const char of chars) {
        await new Promise((resolve) => setTimeout(resolve, 5)); // 50ms から 5ms に変更
        setCurrentResponseParts((prev) => [...prev, char]);
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      setMessages((prev) => [
        ...prev,
        { content: message.content, type: message.type },
      ]);
      setCurrentResponseParts([]);
    }
    setCurrentMessageType(null);
    setIsTyping(false);
  };

  const handleSubmit = async (message: string) => {
    if (!message.trim()) return;

    setMessages((prev) => [...prev, { content: message, type: "text" }]);
    await simulateTyping(mockResponse);
  };

  return (
    <div className="min-h-[50vh] flex flex-col">
      <Header
        ai={ai}
        favorites={[]}
        addFavorite={() => {}}
        removeFavorite={() => {}}
      />
      <div className="flex-1 max-w-4xl w-full mx-auto p-4 flex flex-col">
        <div className="flex-1 overflow-y-auto space-y-4 mb-4 pb-[120px]">
          {messages.map((msg, index) => (
            <MessageBubble
              key={index}
              content={msg.content}
              type={msg.type}
              isUser={index % 2 === 0} // 偶数インデックスをユーザーメッセージとして扱う
            />
          ))}
          {currentResponseParts.length > 0 && currentMessageType && (
            <MessageBubble
              content={currentResponseParts.join("")}
              type={currentMessageType}
              isTyping
              isUser={false}
            />
          )}
          <div ref={messagesEndRef} />
        </div>
        <ChatInput onSubmit={handleSubmit} disabled={isTyping} />
      </div>
    </div>
  );
};

export default Index;
